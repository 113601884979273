(function () {
  $(document).on('ready turbolinks:load', function (event) {
    $('video').each( function(index){
      var data=$(this).data();
      for(i in data) {
        if (i === 'time') {
          re = /^([0-9]+)s/g;
          m = re.exec(data[i]);
          if (m) {
            this.currentTime = Number(m[1]);
          }
        }
      }
    });
  });
}).call(this);