// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "bootstrap";
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/js/all";

import "chartkick/chart.js";

import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import { CandlestickController, CandlestickElement } from 'chartjs-chart-financial';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(CandlestickElement, CandlestickController);
Chart.register(zoomPlugin);
global.Chart = Chart

// import { OhlcElement, OhlcController, CandlestickElement, CandlestickController } from 'chartjs-chart-financial'
// import Chart from 'chart.js/auto' // Easy way of importing everything

// Chart.register(OhlcElement, OhlcController, CandlestickElement, CandlestickController)

require("trix")
require("@rails/actiontext")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//import LocalTime from "local-time"
//LocalTime.start()
require("local-time").start()
require("packs/page.js")
// require("jstimezonedetect")
global.$ = require("jquery")
import "./custom";
require("packs/pager")
require("packs/menu")
// support setting video offset
require("packs/video")

