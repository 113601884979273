(function () {
  $(window).on('scroll', function(){
    if ($(window).scrollTop() > 5) {
      $('nav').addClass('full-size');
    } else {
      $('nav').removeClass('full-size');
    }
  });
  $(document).on('ready turbolinks:load', function (event) {
    $('.menu').on('click',function(){
      console.log('TOGGLE');
      $('.longmenu').toggle();
    });
    $('.longmenu > ul > li > span').on('click', (e) => {
      console.log('Click!');
      $( e.target ).next().toggle();
    });
  });

  $(window).on("load resize turbolinks:load", function() {
    const $dropdown = $(".dropdown");
    const $dropdownToggle = $(".dropdown-toggle");
    const $dropdownMenu = $(".dropdown-menu");
    const showClass = "show";
    if (this.matchMedia("(min-width: 768px)").matches) {
      $dropdown.on('mouseenter', (e)=> {
        const $this = $(e.currentTarget);
        $this.addClass(showClass);
        $this.find($dropdownToggle).attr("aria-expanded", "true");
        $this.find($dropdownMenu).addClass(showClass);
      }).on('mouseleave', (e)=>{
        const $this = $(e.currentTarget);
        $this.removeClass(showClass);
        $this.find($dropdownToggle).attr("aria-expanded", "false");
        $this.find($dropdownMenu).removeClass(showClass);
      });
    } else {
      $dropdown.off("mouseenter mouseleave");
    }
  });

})();