(function () {
  $(document).on('ready turbolinks:load', function (event) {
    function afterPageLoaded(container, html) {
      let position = container.lastChild;
      container.insertAdjacentHTML('beforeend', html);
      $('[data-toggle="popover"]').popover( {
        trigger: "hover",
        placement: "auto",
      });
      if (position == null) {
        window.dispatchEvent(new CustomEvent("pager:load", { detail: container.firstChild }));
      } else {
        window.dispatchEvent(new CustomEvent("pager:load", { detail: position.nextSibling }));
      }
    }

    const pageControlElem = document.getElementById('page-control');
    if (!!pageControlElem) {

      const itemsContainer = document.getElementById('page-container');
      const paginationUrl = pageControlElem.getAttribute('data-page-endpoint');
      const startPage = pageControlElem.getAttribute('data-page');
      let isPaginating = false;
      let currentPage = 1;
      if (startPage != null && startPage != "") {
        currentPage = parseInt(startPage);
      }

      if (paginationUrl.indexOf('?') != -1) {
        baseEndpoint = paginationUrl + "&page=";
      } else {
        baseEndpoint = paginationUrl + '?page='
      }

      function doScroll(scrollY) {
        let intBottomDistance = (window.innerHeight + scrollY) / document.body.clientHeight;
        if (!isPaginating && intBottomDistance > 0.70) {
          isPaginating = true;
          currentPage++;
          pageControlElem.style.visibility = "visible";
          httpRequest = new XMLHttpRequest();
          httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState === XMLHttpRequest.DONE) {
              if (httpRequest.status === 200) {
                afterPageLoaded(itemsContainer, httpRequest.responseText);
                isPaginating = false;
                if (document.body.clientHeight < window.innerHeight) {
                  doScroll(window.innerHeight);
                }
              } else if (httpRequest.status === 206) {
                pageControlElem.style.visibility = "hidden";
                afterPageLoaded(itemsContainer, httpRequest.responseText);
              } else if (httpRequest.status === 500) {
                itemsContainer.insertAdjacentHTML('beforeend', "<h6>Server Error during page load</h6>");
                pageControlElem.style.visibility = "hidden";
              }
            }
          };
          httpRequest.open('GET', `${baseEndpoint}${currentPage}`, true);
          httpRequest.send();
        }
      }

      $(document).on('scroll', function (event) {
        const scrollY = window.scrollY;
        if (!isPaginating) {
          window.requestAnimationFrame(function () {
            doScroll(scrollY);
          });
        }
      });

      doScroll(window.innerHeight);

    } else {
      $(document).off('scroll');
    }
  });

}).call(this);