(function() {
  jQuery(function() {
    // <%= link_to "a post", User.first, remote: true %>
    if ($('#infinite-scrolling').length > 0) {
      $(window).on('scroll', function() {
        var more_posts_url;
        more_posts_url = $('.pagination .next_page a').attr('href');
        if (more_posts_url && $(window).scrollTop() > $(document).height() - $(window).height() - 60) {
          var load_progress = document.getElementById('load_progress');
          load_progress.style.visibility = 'visible'; // 'hidden'
          $('.pagination').html('<img src="/assets/ajax-loader.gif" alt="Loading..." title="Loading..." />');
          $.getScript(more_posts_url);
        }
      });
    }
  });

}).call(this);